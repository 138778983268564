// import PropTypes from "prop-types";

import React, { useContext, useEffect, useState } from "react";
import { graphql, Link, StaticQuery } from "gatsby";
import { AppContext } from "~context/AppContext";
import { DocumentContext } from "~context/DocumentContext";
import CursorHoverable from "~components/CursorHoverable";
import ArrowUp from "~components/svg/ArrowUp";
import CartIcon from "~components/svg/CartIcon";
import Cross from "~components/svg/Cross";
import Wordmark from "~components/svg/Wordmark";

const query = graphql`
  query Header {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "index-page" } } }
    ) {
      edges {
        node {
          frontmatter {
            exhibitionStatus
          }
        }
      }
    }
  }
`;

const Header = () => {
  const {
    cart,
    cartActive,
    setCartActive,
    darkMode,
    setDarkMode,
    menuActive,
    setMenuActive,
    pathname
  } = useContext(AppContext);
  const { device, scrollTop, windowWidth } = useContext(DocumentContext);

  const [darkButtonVisible, setDarkButtonVisible] = useState(false);

  //
  // scroll link

  let backToTop = false;

  if (device && device === `desktop` && scrollTop > windowWidth * 0.4) {
    backToTop = true;
  }

  const scrollToTop = () => {
    if (typeof window === `undefined`) {
      return;
    }

    window.scrollTo({
      top: 0,
      behavior: `smooth`
    });
  };

  //

  useEffect(() => {
    if (pathname === `/`) {
      setDarkButtonVisible(false);
    } else {
      setDarkButtonVisible(true);
    }
  }, [pathname]);

  //

  let cartCount = 0;

  cart.forEach(cartItem => {
    cartCount += cartItem.quantity;
  });

  //

  return (
    <StaticQuery
      query={query}
      render={data => {
        let exhibitionStatus;

        if (data?.allMarkdownRemark?.edges?.[0]?.node) {
          data.allMarkdownRemark.edges.forEach(({ node }) => {
            if (node?.frontmatter?.exhibitionStatus) {
              ({ exhibitionStatus } = node.frontmatter);
            }
          });
        }

        if (exhibitionStatus === `None`) {
          if (darkButtonVisible) {
            setDarkButtonVisible(false);
          }
        }

        //
        // color management

        let foregroundColor = `white`;

        if (exhibitionStatus === `None`) {
          foregroundColor = `white`;
        } else {
          foregroundColor = `black`;
        }

        let backgroundColor = `transparent`;

        if (device && device !== `desktop`) {
          if (menuActive) {
            backgroundColor = `black`;
            foregroundColor = `white`;
          } else {
            switch (pathname) {
              case `/`:
                backgroundColor = `off-white`;
                foregroundColor = `black`;

                break;

              default:
                backgroundColor = `white`;
                foregroundColor = `black`;

                break;
            }
          }
        }

        return (
          <>
            <header
              className={`header ${backToTop ? `back-to-top` : ``} ${
                menuActive ? `menu-active` : ``
              } animation-appear-down-slow animation-delay-1 w-full fixed top-0 right-0 left-0 z-30 xs:z-50 pt-v2 pr-v3 pl-v3 pb-v1 white bg-${backgroundColor}`}
            >
              <button
                type="button"
                className="desktop-only absolute top-0 right-0 z-10 mr-v2"
                onClick={scrollToTop}
              >
                <CursorHoverable className="header__back w-full h-full relative flex bg-white text-black b1">
                  <span className="mr-v1">Back To Top</span>
                  <ArrowUp />
                </CursorHoverable>
              </button>

              <nav
                className={`header__nav transition-color w-full relative z-20 flex items-end xs:items-center justify-between text-${foregroundColor}`}
              >
                {(!(cartActive && device && device !== `desktop`) && (
                  <div className="relative flex items-center">
                    <CursorHoverable>
                      <Link
                        to="/"
                        className="flex items-center xs:justify-center"
                        onClick={() => setMenuActive(false)}
                      >
                        <Wordmark
                          className="header__logo mr-v1 xs:mr-v4"
                          color={foregroundColor}
                        />

                        {exhibitionStatus !== `None` && (
                          <span
                            className={`header__caption ${
                              device && device === `desktop` ? `b1` : `b2`
                            } block xs:mt-v1`}
                          >
                            Exhibitions
                          </span>
                        )}
                      </Link>
                    </CursorHoverable>
                  </div>
                )) || (
                  <button
                    type="button"
                    className="header__nav__touch-close animation-appear-right-slow animation-delay-1 relative flex items-center justify-center"
                    onClick={() => setCartActive(false)}
                  >
                    <Cross
                      className="w-full h-full"
                      color={darkMode ? `black` : `white`}
                    />
                  </button>
                )}

                {darkButtonVisible && (
                  <div className="w-full h-full absolute top-0 right-0 bottom-0 left-0 flex items-center justify-center z-10 pointer-events-none">
                    <CursorHoverable className="desktop-only">
                      <button
                        type="button"
                        className="pointer-events-auto hover-underline b1 italic"
                        onClick={() => setDarkMode(!darkMode)}
                      >
                        {darkMode ? `Light` : `Dark`}mode
                      </button>
                    </CursorHoverable>
                  </div>
                )}

                {(exhibitionStatus === `Active` && (
                  <ul className="header__links desktop-only relative flex items-center justify-end">
                    <li className="ml-v3">
                      <CursorHoverable>
                        <Link to="/" className="hover-underline">
                          About
                        </Link>
                      </CursorHoverable>
                    </li>

                    <li className="ml-v3">
                      <CursorHoverable>
                        <Link to="/artists" className="hover-underline">
                          Artists
                        </Link>
                      </CursorHoverable>
                    </li>

                    <li className="ml-v3">
                      <CursorHoverable>
                        <Link to="/prints" className="hover-underline">
                          Shop Prints
                        </Link>
                      </CursorHoverable>
                    </li>

                    <li className="ml-v3">
                      <CursorHoverable>
                        <button
                          type="button"
                          onClick={() => setCartActive(!cartActive)}
                        >
                          <CartIcon
                            className="header__cart opacity-50"
                            color={foregroundColor}
                          />
                        </button>
                      </CursorHoverable>
                    </li>
                  </ul>
                )) || (
                  <ul className="header__links desktop-only relative flex items-center justify-end">
                    <li className="ml-v3">
                      <CursorHoverable>
                        <a
                          href="https://www.with-you.studio/about"
                          className="hover-underline"
                        >
                          About
                        </a>
                      </CursorHoverable>
                    </li>

                    <li className="ml-v3">
                      <CursorHoverable>
                        <a
                          href="https://www.with-you.studio/work"
                          className="hover-underline"
                        >
                          Work
                        </a>
                      </CursorHoverable>
                    </li>

                    <li className="ml-v3">
                      <CursorHoverable>
                        <Link to="/#exhibitions" className="hover-underline">
                          Exhibition
                        </Link>
                      </CursorHoverable>
                    </li>

                    <li className="ml-v3">
                      <CursorHoverable>
                        <a
                          href="https://www.with-you.studio/contact"
                          className="hover-underline"
                        >
                          Contact
                        </a>
                      </CursorHoverable>
                    </li>
                  </ul>
                )}

                {exhibitionStatus === `Active` && (
                  <div className="relative flex items-center justify-center touch-only--flex">
                    {cartActive && (
                      <p className="animation-appear-left-slow mr-v3 b1">
                        {cartCount} item{cartCount === 1 ? `` : `s`}
                      </p>
                    )}

                    <button
                      type="button"
                      className="header__cart--touch w-6 h-6 relative mr-v9"
                      onClick={() => setCartActive(!cartActive)}
                    >
                      <CartIcon
                        className="w-full h-full opacity-50 xs:opacity-100"
                        color={foregroundColor}
                      />
                    </button>

                    <button
                      type="button"
                      className="header__menu"
                      onClick={() => setMenuActive(!menuActive)}
                    >
                      <ul className="w-5 h-5 relative flex flex-col justify-between">
                        <li
                          className={`header__menu__line header__menu__line--0 w-full relative bg-${foregroundColor}`}
                          style={{ height: `2px` }}
                        ></li>
                        <li
                          className={`header__menu__line header__menu__line--1 w-full relative bg-${foregroundColor}`}
                          style={{ height: `2px` }}
                        ></li>
                        <li
                          className={`header__menu__line header__menu__line--2 w-full relative bg-${foregroundColor}`}
                          style={{ height: `2px` }}
                        ></li>
                      </ul>
                    </button>
                  </div>
                )}

                {exhibitionStatus === `None` && (
                  <button
                    type="button"
                    className="header__menu touch-only"
                    onClick={() => setMenuActive(!menuActive)}
                  >
                    <ul className="w-5 h-5 relative flex flex-col justify-between">
                      <li
                        className={`header__menu__line header__menu__line--0 w-full relative bg-${foregroundColor}`}
                        style={{ height: `2px` }}
                      ></li>
                      <li
                        className={`header__menu__line header__menu__line--1 w-full relative bg-${foregroundColor}`}
                        style={{ height: `2px` }}
                      ></li>
                      <li
                        className={`header__menu__line header__menu__line--2 w-full relative bg-${foregroundColor}`}
                        style={{ height: `2px` }}
                      ></li>
                    </ul>
                  </button>
                )}
              </nav>
            </header>

            {darkButtonVisible && device && device !== `desktop` && (
              <div
                className={`header__touch-switcher transition-background w-full relative flex items-center justify-center z-10 pt-v20 pb-v12 pointer-events-none bg-${
                  darkMode ? `black` : `white`
                } text-${darkMode ? `white` : `black`}`}
              >
                <button
                  type="button"
                  className="pointer-events-auto mt-v5 b2 italic"
                  onClick={() => setDarkMode(!darkMode)}
                >
                  {darkMode ? `Light` : `Dark`}mode
                </button>
              </div>
            )}
          </>
        );
      }}
    />
  );
};

// Header.defaultProps = {
// };

// Header.propTypes = {
// };

export default Header;
