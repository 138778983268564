import React from "react";
import PropTypes from "prop-types";

const CartIcon = ({ className, color }) => (
  <svg className={className} viewBox="0 0 20 20" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.50001 5.35693H13.5C13.1691 3.72676 11.7278 2.49979 10 2.49979C8.27217 2.49979 6.83092 3.72676 6.50001 5.35693ZM4.32992 5.35693C4.68142 2.53814 7.08599 0.356934 10 0.356934C12.914 0.356934 15.3186 2.53814 15.6701 5.35693H20V19.6426H0V5.35693H4.32992Z"
      fill={color}
    />
  </svg>
);

CartIcon.defaultProps = {
  className: ``,
  color: ``
};

CartIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string
};

export default CartIcon;
