import React from "react";
import PropTypes from "prop-types";
import CursorHoverable from "~components/CursorHoverable";

const Button = ({ children, className, color, onClick, transparent }) => {
  return (
    <CursorHoverable>
      <button
        type="button"
        className={`button button--${color} ${
          transparent ? `button--transparent` : ``
        } ${className} relative block`}
        onClick={onClick}
      >
        {children}
      </button>
    </CursorHoverable>
  );
};

Button.defaultProps = {
  color: ``,
  className: ``,
  onClick: () => {},
  transparent: false
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  transparent: PropTypes.bool
};

export default Button;
