/* eslint-disable camelcase */
import React, { createContext, useEffect, useState } from "react";
import getSymbolFromCurrency from "currency-symbol-map";
import PropTypes from "prop-types";
import { useCookies } from "react-cookie";
import { globalHistory } from "@reach/router";
import { pushProductEvent } from "~utils/analytics";
import { fancyError, fancyWarning } from "~utils/helpers";
import { useTimeout } from "~utils/hooks";
import {
  getCheckoutURL,
  getInventoryIdByVariantTitle,
  getInventoryLevelsByIds
} from "~utils/shopify";

export const AppContext = createContext({});

const AppProvider = ({ children }) => {
  const [cursorBackground, setCursorBackground] = useState(`blue`);
  const [cursorMode, setCursorMode] = useState(null);
  const [cursorVisible, setCursorVisible] = useState(false);
  const [activeCurrency, setActiveCurrency] = useState(null);
  const [activeCurrencySymbol, setActiveCurrencySymbol] = useState(null);
  const [cart, setCart] = useState([]);
  const [cartActive, setCartActive] = useState(false);
  const [cookies, setCookie] = useCookies(null);
  const [cookiesAccepted, setCookiesAccepted] = useState(true);
  const [cookieMessageActive, setCookieMessageActive] = useState(false);
  const [darkMode, setDarkMode] = useState(true);
  const [headerColor, setHeaderColor] = useState(null);
  const [inventoryCache, setInventoryCache] = useState(null);
  const [inventoryFetched, setInventoryFetched] = useState(null);
  const [menuActive, setMenuActive] = useState(false);
  const [pathname, setPathname] = useState(null);
  const [rendered, setRendered] = useState(false);
  const [shuffledArtists, setShuffledArtists] = useState(null);
  const [skuPreselect, setSkuPreselect] = useState(null);
  const [wishlistActive, setWishlistActive] = useState(false);
  const [wishlist, setWishlist] = useState([]);

  //

  const setDefaultCurrency = () => {
    let defaultCurrency;
    let regionCode = `au`;

    if (process.env.GATSBY_REGION_CODE) {
      regionCode = process.env.GATSBY_REGION_CODE;
    }

    switch (regionCode.toLowerCase()) {
      case `au`:
        defaultCurrency = `AUD`;
        break;

      case `eu`:
        defaultCurrency = `EUR`;
        break;

      case `us`:
      default:
        defaultCurrency = `USD`;
        break;
    }

    setActiveCurrency(defaultCurrency);
    setActiveCurrencySymbol(getSymbolFromCurrency(defaultCurrency));
  };

  //

  useTimeout(() => {
    setRendered(true);
  }, 1500);

  useEffect(() => {
    if (window) {
      setPathname(window.location.pathname);
    }

    setDefaultCurrency();

    //
    // [cookie] cart

    if (!cookies?.accepted) {
      setCookieMessageActive(true);
    } else {
      setCookiesAccepted(true);

      if (cookies?.[`${process.env.GATSBY_REGION_CODE}_cart`]) {
        const parsedCart = cookies[`${process.env.GATSBY_REGION_CODE}_cart`];

        let valid =
          Array.isArray(cookies[`${process.env.GATSBY_REGION_CODE}_cart`]) &&
          cookies?.[`${process.env.GATSBY_REGION_CODE}_cart`]?.[0];

        if (valid) {
          parsedCart.forEach(cookieCartItem => {
            if (!valid) {
              return;
            }

            if (
              typeof cookieCartItem === `undefined` ||
              cookieCartItem === null ||
              cookieCartItem === false ||
              cookieCartItem === `` ||
              !cookieCartItem?.variantId ||
              !cookieCartItem?.quantity
            ) {
              valid = false;
            }
          });
        }

        if (!valid || process.env.GATSBY_RESET_COOKIES) {
          fancyWarning(`Resetting cart data`);
          setCookie([`${process.env.GATSBY_REGION_CODE}_cart`], [], {
            path: `/`
          });
          setCart([]);
        } else {
          setCart(parsedCart);
        }
      }

      if (cookies?.currency) {
        setActiveCurrency(cookies.currency);
      }
    }

    return globalHistory.listen(({ location }) => {
      setPathname(location.pathname);
    });
  }, []);

  // [cookie] acceptance

  useEffect(() => {
    if (cookiesAccepted) {
      setCookie(`accepted`, true, { path: `/` });
    }
  }, [cookiesAccepted]);

  // [cookie] currency

  useEffect(() => {
    if (cookiesAccepted) {
      setCookie(`${process.env.GATSBY_REGION_CODE}_currency`, activeCurrency, {
        path: `/`
      });
    }

    setActiveCurrencySymbol(getSymbolFromCurrency(activeCurrency));
  }, [activeCurrency, cookiesAccepted]);

  // [cookie] location

  //

  const addToCart = (productWithVariant, quantity = 1) => {
    if (!productWithVariant?.variant) {
      return;
    }

    let existingCartPosition = null;

    const cartClone = JSON.parse(JSON.stringify(cart));

    cartClone.forEach((cartItem, cartIndex) => {
      if (existingCartPosition !== null) {
        return;
      }

      if (cartItem.variantId === productWithVariant.variant.id) {
        existingCartPosition = cartIndex;
      }
    });

    if (existingCartPosition === null) {
      cartClone.push({
        quantity,
        variantId: productWithVariant.variant.id
      });
    } else {
      cartClone[existingCartPosition].quantity += quantity;
    }

    setCartActive(true);
    setCart(cartClone);

    if (cookiesAccepted) {
      setCookie(`${process.env.GATSBY_REGION_CODE}_cart`, cartClone, {
        path: `/`
      });

      pushProductEvent(
        productWithVariant,
        activeCurrency,
        `addToCart`,
        quantity
      );
    }
  };

  const checkout = () => {
    if (
      !process.env.GATSBY_SHOPIFY_STORE ||
      process.env.GATSBY_SHOPIFY_STORE === ``
    ) {
      fancyError(`Shopify environment variables have not been defined.`);

      return;
    }

    setCartActive(false);

    getCheckoutURL(cart, activeCurrency).then(response => {
      response.json().then(({ data }) => {
        if (data?.checkoutCreate?.checkout?.webUrl) {
          const { webUrl } = data.checkoutCreate.checkout;

          // prod check-in routing goes here

          window.location.href = webUrl;
        }
      });
    });
  };

  const decreaseQuantityByCartIndex = cartIndex => {
    if (!cart?.[cartIndex]) {
      return;
    }

    const cartClone = JSON.parse(JSON.stringify(cart));

    if (cartClone[cartIndex].quantity <= 1) {
      cartClone.splice(cartIndex, 1);
    } else {
      cartClone[cartIndex].quantity -= 1;
    }

    setCart(cartClone);

    if (cookiesAccepted) {
      setCookie([`${process.env.GATSBY_REGION_CODE}_cart`], cartClone, {
        path: `/`
      });

      pushProductEvent(cartClone[cartIndex], activeCurrency, `removeFromCart`);
    }
  };

  const increaseQuantityByCartIndex = cartIndex => {
    if (!cart?.[cartIndex]) {
      return;
    }

    const cartClone = JSON.parse(JSON.stringify(cart));

    cartClone[cartIndex].quantity += 1;

    setCart(cartClone);

    if (cookiesAccepted) {
      setCookie([`${process.env.GATSBY_REGION_CODE}_cart`], cartClone, {
        path: `/`
      });

      pushProductEvent(cartClone[cartIndex], activeCurrency, `addToCart`);
    }
  };

  const removeFromCartByIndex = cartIndex => {
    if (!cart?.[cartIndex]) {
      return;
    }

    const cartClone = JSON.parse(JSON.stringify(cart));

    cartClone.splice(cartIndex, 1);

    setCart(cartClone);

    if (cookiesAccepted) {
      setCookie([`${process.env.GATSBY_REGION_CODE}_cart`], cartClone, {
        path: `/`
      });

      pushProductEvent(
        cart[cartIndex],
        activeCurrency,
        `removeFromCart`,
        cart[cartIndex].quantity
      );
    }
  };

  const refreshInventoryCache = (products, adminProducts) => {
    if (
      typeof window === `undefined` ||
      typeof fetch === `undefined` ||
      window.location.href.includes(`localhost`)
    ) {
      setInventoryCache({});
      return;
    }

    const variantsByInventoryId = {};

    products.forEach(product => {
      product.variants.forEach(variant => {
        const inventoryId = getInventoryIdByVariantTitle(
          adminProducts.edges,
          product.handle,
          variant.title
        );

        if (inventoryId && inventoryId !== ``) {
          variantsByInventoryId[inventoryId] = variant;
        }
      });
    });

    getInventoryLevelsByIds(Object.keys(variantsByInventoryId).join()).then(
      response => {
        response.json().then(inventory => {
          const newInventoryCache = {};

          if (!inventory?.inventory_levels?.[0]) {
            setInventoryCache(newInventoryCache);
            return;
          }

          inventory.inventory_levels.forEach(inventoryLevel => {
            const variant =
              variantsByInventoryId[inventoryLevel.inventory_item_id];

            if (typeof inventoryLevel.available === `undefined`) {
              newInventoryCache[variant.id] = 0;

              return;
            }

            newInventoryCache[variant.id] = inventoryLevel.available;
          });

          setInventoryCache(newInventoryCache);
        });
      }
    );
  };

  useEffect(() => {
    if (inventoryCache) {
      setInventoryFetched(true);
    }
  }, [inventoryCache]);

  //

  return (
    <AppContext.Provider
      value={{
        cart,
        setCart,
        cartActive,
        setCartActive,
        activeCurrencySymbol,
        setActiveCurrencySymbol,
        activeCurrency,
        setActiveCurrency,
        cookiesAccepted,
        setCookiesAccepted,
        cookieMessageActive,
        setCookieMessageActive,
        cursorBackground,
        setCursorBackground,
        cursorMode,
        setCursorMode,
        cursorVisible,
        setCursorVisible,
        darkMode,
        setDarkMode,
        inventoryCache,
        setInventoryCache,
        refreshInventoryCache,
        inventoryFetched,
        setInventoryFetched,
        headerColor,
        setHeaderColor,
        menuActive,
        setMenuActive,
        pathname,
        rendered,
        setRendered,
        skuPreselect,
        setSkuPreselect,
        shuffledArtists,
        setShuffledArtists,
        wishlist,
        setWishlist,
        wishlistActive,
        setWishlistActive,
        //
        addToCart,
        checkout,
        decreaseQuantityByCartIndex,
        increaseQuantityByCartIndex,
        removeFromCartByIndex
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

AppProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AppProvider;
