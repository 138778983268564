/* eslint-disable react/prop-types */

import React, { useContext, useEffect, useRef, useState } from "react";
import { graphql, Link, StaticQuery } from "gatsby";
import Img from "gatsby-image";
import { AppContext } from "~context/AppContext";
import { DocumentContext } from "~context/DocumentContext";
import Button from "~components/Button";
import CursorHoverable from "~components/CursorHoverable";
import ScreenHeight from "~components/ScreenHeight";
import CartIcon from "~components/svg/CartIcon";
import Cross from "~components/svg/Cross";
import { fancyError, fancyLog, validateEmail } from "~utils/helpers";
import { useKeyPress } from "~utils/hooks";
import { getPriceByCurrency, parseProducts } from "~utils/shopify";

const query = graphql`
  query Nav {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "artist-page" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            shopifyHandle
            title
            artist
            cartImage {
              childImageSharp {
                fluid(maxWidth: 256) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
              publicURL
            }
            cartImageXS {
              childImageSharp {
                fluid(maxWidth: 128) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
              publicURL
            }
            cartImageAlt
          }
        }
      }
    }
    allShopifyProduct {
      edges {
        node {
          id
          title
          description
          handle
          images {
            originalSrc
          }
          productType
          vendor
          variants {
            id
            title
            image {
              originalSrc
            }
            price
            presentmentPrices {
              edges {
                node {
                  price {
                    amount
                    currencyCode
                  }
                }
              }
            }
            selectedOptions {
              name
              value
            }
          }
        }
      }
    }
    allShopifyAdminProduct {
      edges {
        node {
          products {
            handle
            variants {
              alternative_id
              inventory_item_id
              title
            }
          }
        }
      }
    }
  }
`;

const Nav = () => {
  const {
    activeCurrency,
    activeCurrencySymbol,
    cart,
    cartActive,
    setCartActive,
    darkMode,
    menuActive,
    setMenuActive,
    inventoryCache,
    refreshInventoryCache,
    //
    checkout,
    decreaseQuantityByCartIndex,
    increaseQuantityByCartIndex,
    removeFromCartByIndex
  } = useContext(AppContext);
  const { device } = useContext(DocumentContext);

  const formRef = useRef();

  const [email, setEmail] = useState(null);
  const [inventoryLoaded, setInventoryLoaded] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [valid, setValid] = useState(false);

  //

  const close = () => {
    setCartActive(false);
    setMenuActive(false);
  };

  const onSubmit = e => {
    e.preventDefault();

    if (
      typeof window !== `undefined` &&
      window.location.href.includes(`localhost:8000`)
    ) {
      setSubmitting(true);

      setTimeout(() => {
        setSubmitting(false);
        setSubmitted(true);
      }, 3000);

      return false;
    }

    if (
      !valid ||
      submitting ||
      submitted ||
      !process.env.GATSBY_NETLIFY_FUNCTIONS ||
      !process.env.GATSBY_MAILCHIMP_API_KEY ||
      !process.env.GATSBY_MAILCHIMP_LIST_ID
    ) {
      return false;
    }

    setSubmitting(true);

    const mailchimpData = {
      email,
      list_id: process.env.GATSBY_MAILCHIMP_LIST_ID
    };

    fetch(`${process.env.GATSBY_NETLIFY_FUNCTIONS}/mailchimp`, {
      body: JSON.stringify(mailchimpData),
      method: `POST`
    })
      .then(() => {
        fancyLog(`Mailchimp Complete`);
        setSubmitting(false);
        setSubmitted(true);
      })
      .catch(error => {
        fancyError(error);
      });

    return false;
  };

  //

  useEffect(() => {
    setValid(validateEmail(email));
  }, [email]);

  //

  const escKeyPressed = useKeyPress(`Escape`);

  useEffect(() => {
    close();
  }, [escKeyPressed]);

  //

  return (
    <StaticQuery
      query={query}
      render={data => {
        const products = parseProducts(data);

        if (!inventoryLoaded) {
          // setTimeout fixes the static query/render lifecycle warning
          setTimeout(() => {
            setInventoryLoaded(true);
            refreshInventoryCache(products, data.allShopifyAdminProduct);
          });
        }

        const navCart = [];

        let cartCount = 0;
        let cartTotal = 0;

        cart.forEach(cartItem => {
          products.forEach(productItem => {
            productItem.variants.forEach(productItemVariant => {
              if (productItemVariant.id === cartItem.variantId) {
                const lineItemCost =
                  getPriceByCurrency(productItemVariant, activeCurrency) *
                  cartItem.quantity;

                cartCount += cartItem.quantity;
                cartTotal += lineItemCost;

                let image;
                if (
                  productItem?.frontmatter?.cartImage?.childImageSharp?.fluid
                ) {
                  image = productItem.frontmatter.cartImage.childImageSharp;
                } else if (productItem?.frontmatter?.cartImage?.publicURL) {
                  image = productItem.frontmatter.cartImage.publicURL;
                } else if (productItemVariant?.image?.originalSrc) {
                  image = productItemVariant.image.originalSrc;
                }

                let imageXS;
                if (
                  productItem?.frontmatter?.cartImageXS?.childImageSharp?.fluid
                ) {
                  image = productItem.frontmatter.cartImageXS.childImageSharp;
                } else if (productItem?.frontmatter?.cartImageXS?.publicURL) {
                  image = productItem.frontmatter.cartImageXS.publicURL;
                } else if (image) {
                  imageXS = image;
                }

                navCart.push({
                  id: cartItem.variantId,
                  image,
                  imageXS,
                  imageAlt:
                    productItem.frontmatter.cartImageAlt ||
                    productItem.frontmatter.title,
                  quantity: cartItem.quantity,
                  //
                  price: `${activeCurrencySymbol}${parseFloat(
                    lineItemCost
                  ).toFixed(2)}`,
                  slug: productItem.slug,
                  artist: productItem.frontmatter.artist,
                  title: productItem.frontmatter.title
                });
              }
            });
          });
        });

        // console.log(`inventoryCache: `, inventoryCache);

        //

        return (
          <div
            className={`nav ${cartActive ? `cart-active` : ``} ${
              menuActive ? `menu-active` : ``
            } w-screen h-screen fixed top-0 right-0 bottom-0 left-0 flex items-center justify-between z-40 pointer-events-none`}
          >
            <div
              role="presentation"
              className={`nav__background ${
                cartActive || menuActive
                  ? `opacity-50 pointer-events-auto`
                  : `opacity-0`
              } transition-opacity w-screen h-screen absolute top-0 right-0 bottom-0 left-0 z-10 bg-black`}
              onClick={close}
            ></div>

            <ScreenHeight
              className={`nav__menu ${
                menuActive ? `pointer-events-auto` : ``
              } touch-only h-full absolute top-0 left-0 z-20 ${
                darkMode ? `bg-black text-white` : `bg-white text-black`
              }`}
            >
              {menuActive && (
                <div className="h-full grid">
                  <ul className="nav__menu__links grid-end-24">
                    <li className="animation-appear-down animation-delay-1 f5">
                      <a
                        href="https://www.with-you.studio/about"
                        className="block pt-v3 pb-v3"
                        onClick={() => setMenuActive(false)}
                      >
                        About
                      </a>
                    </li>

                    <li className="animation-appear-down animation-delay-2 f5">
                      <a
                        href="https://www.with-you.studio/work"
                        className="block pt-v3 pb-v3"
                        onClick={() => {
                          setWorkLink(`Recent Projects`);
                          setMenuActive(false);
                        }}
                      >
                        Work
                      </a>
                    </li>

                    <li className="animation-appear-down animation-delay-3 f5">
                      <Link to="/#exhibitions" className="block pt-v3 pb-v3">
                        Exhibition
                      </Link>
                    </li>

                    <li className="animation-appear-down animation-delay-4 f5">
                      <a
                        href="https://www.with-you.studio/contact"
                        className="block pt-v3 pb-v3"
                        onClick={() => setMenuActive(false)}
                      >
                        Contact
                      </a>
                    </li>
                  </ul>

                  <div className="nav__menu__form animation-appear-down animation-delay-5 grid-end-24 relative pt-v12 pb-v16 flex flex-col justify-between">
                    <form
                      ref={formRef}
                      className="w-full relative"
                      onSubmit={onSubmit}
                    >
                      <div className="grid-end-11 xs:grid-end-24 grid-start-2 xs:grid-start-1">
                        <h3 className="b2">Stay updated</h3>

                        <input
                          className={`w-full relative block mt-v2 xs:mt-v6 pb-v1 border-b-${
                            darkMode ? `white` : `black`
                          } ${device && device === `desktop` ? `f3` : `f5`}`}
                          onChange={e => setEmail(e.currentTarget.value)}
                          placeholder="Enter your email"
                          type="email"
                        />

                        <div className="flex justify-end">
                          <CursorHoverable>
                            <input
                              className={`mt-v1 xs:mt-v3 text-right text-${
                                darkMode ? `white` : `black`
                              } ${
                                valid
                                  ? `cursor-pointer hover-underline text-white`
                                  : `pointer-events-none text-grey`
                              } ${
                                submitting || submitted
                                  ? `opacity-50 pointer-events-none`
                                  : ``
                              }`}
                              readOnly={submitting || submitted}
                              type="submit"
                              value={submitted ? `Thanks!` : `Submit`}
                            />
                          </CursorHoverable>
                        </div>
                      </div>
                    </form>

                    <div className="w-full relative b1">
                      {/* <a
                        href="https://www.with-you.studio"
                        className="w-full relative block pt-v2 pb-v2 b1"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        + Visit With You
                      </a> */}

                      <a
                        href="https://www.instagram.com/with_you.exhibition/"
                        className="w-full relative block pt-v2 pb-v2 b1"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        + Instagram
                      </a>
                    </div>
                  </div>
                </div>
              )}
            </ScreenHeight>

            <ScreenHeight
              className={`nav__cart ${
                cartActive ? `pointer-events-auto` : ``
              } transition-transform h-full absolute top-0 right-0 z-50 ${
                darkMode ? `bg-white text-black` : `bg-black text-white`
              }`}
            >
              <div className="w-full relative flex items-center justify-between">
                <CursorHoverable>
                  <button
                    className="nav__cart__close desktop-only relative flex items-center justify-center"
                    onClick={close}
                    type="button"
                  >
                    <Cross
                      className="w-full h-full relative block"
                      color={darkMode ? `black` : `white`}
                    />
                  </button>
                </CursorHoverable>

                <div className="desktop-only--flex">
                  <p className="mr-v1 b1">
                    {cartCount} item{cartCount === 1 ? `` : `s`}
                  </p>

                  <CursorHoverable>
                    <button
                      type="button"
                      onClick={() => setCartActive(!cartActive)}
                    >
                      <CartIcon
                        className="nav__cart__icon"
                        color={darkMode ? `black` : `white`}
                      />
                    </button>
                  </CursorHoverable>
                </div>
              </div>

              {(cartActive && navCart?.[0] && (
                <>
                  <ul className="nav__cart__list relative overflow-y-scroll">
                    {navCart.map((cartItem, cartItemIndex) => {
                      return (
                        <li
                          key={cartItem.id}
                          className="nav__cart__item relative flex items-stretch mb-v4 xs:mb-v12"
                        >
                          <CursorHoverable className="nav__cart__item__remove w-6 h-6 absolute top-0 right-0 z-10">
                            <button
                              type="button"
                              className="w-full h-full relative block"
                              onClick={() =>
                                removeFromCartByIndex(cartItemIndex)
                              }
                            >
                              <Cross
                                className="w-full h-full"
                                color={darkMode ? `black` : `white`}
                              />
                            </button>
                          </CursorHoverable>

                          <div className="nav__cart__item__image relative block">
                            <div className="a2 w-full relative">
                              <figure className="w-full h-full absolute top-0 right-0 bottom-0 left-0 flex items-center justify-center">
                                {(cartItem.image?.fluid && (
                                  <Img
                                    className="w-full h-full relative block object-cover"
                                    fluid={cartItem.image.fluid}
                                    alt={cartItem.imageAlt}
                                  />
                                )) || (
                                  <>
                                    {cartItem.image && (
                                      <img
                                        className="w-full h-full relative block object-cover"
                                        src={cartItem.image}
                                        alt={cartItem.imageAlt}
                                      />
                                    )}
                                  </>
                                )}
                              </figure>
                            </div>
                          </div>

                          <article className="w-full relative pr-v3 xs:pr-v8">
                            <header>
                              <h2 className="f5">{cartItem.title}</h2>
                              <h2 className="b1">By {cartItem.artist}</h2>
                            </header>

                            <h3 className="nav__cart__item__price b1">
                              {cartItem.price}
                            </h3>

                            <div className="relative flex items-center">
                              <p className="xs:mt-v1 b2">Quantity:</p>

                              <CursorHoverable>
                                <button
                                  type="button"
                                  className={`nav__cart__item__quantity ${
                                    device === `desktop` ? `b1` : `f5`
                                  }`}
                                  onClick={() =>
                                    decreaseQuantityByCartIndex(cartItemIndex)
                                  }
                                >
                                  -
                                </button>
                              </CursorHoverable>

                              <p className="b2">{cartItem.quantity}</p>

                              <CursorHoverable>
                                <button
                                  type="button"
                                  className={`nav__cart__item__quantity ${
                                    device === `desktop` ? `b1` : `f5`
                                  }`}
                                  onClick={() =>
                                    increaseQuantityByCartIndex(cartItemIndex)
                                  }
                                >
                                  +
                                </button>
                              </CursorHoverable>
                            </div>
                          </article>
                        </li>
                      );
                    })}
                  </ul>

                  <div
                    className={`nav__cart__controls w-full absolute right-0 bottom-0 left-0 z-10 bg-${
                      darkMode ? `white` : `black`
                    }`}
                  >
                    <div
                      className={`nav__cart__list__bg ${
                        darkMode ? `` : `dark`
                      } absolute right-0 left-0 z-10 pointer-events-none`}
                    ></div>

                    <div className="w-full relative mb-v2 xs:mb-v4 flex items-center justify-between">
                      <h3
                        className={`${
                          device && device === `desktop` ? `f5` : `b1`
                        }`}
                      >
                        Total:
                      </h3>
                      <h2 className="f2">
                        ${parseFloat(cartTotal).toFixed(2)}
                      </h2>
                    </div>

                    <p className="b1 text-dark-grey">Price includes 10% GST</p>
                    <p className="xs:mt-v2 b1 text-dark-grey">
                      Shipping calculated at checkout
                    </p>

                    <Button
                      className="w-full mt-v1 xs:mt-v5"
                      color={`${darkMode ? `black` : `white`}`}
                      onClick={checkout}
                    >
                      <span className="f5">Checkout</span>
                    </Button>
                  </div>
                </>
              )) || (
                <h3 className="animation-appear animation-delay-2 mt-v4 xs:mt-v20 f5 text-center text-black">
                  Your cart is empty.
                </h3>
              )}
            </ScreenHeight>
          </div>
        );
      }}
    />
  );
};

export default Nav;
